import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const provider = new firebase.auth.GoogleAuthProvider();

const firebaseConfig = {
  apiKey: "AIzaSyAem62PGav5B0HlbpeXkOjej2kLPUw11aU",
  authDomain: "squab-the-post-app.firebaseapp.com",
  projectId: "squab-the-post-app",
  storageBucket: "squab-the-post-app.appspot.com",
  messagingSenderId: "560424351308",
  appId: "1:560424351308:web:f8d3396a25fcb31144e6e9",
  measurementId: "G-PRHXJZ38TQ"
};

/*const firebaseConfig_Users = {
  apiKey: "AIzaSyBOy9qjF3WqBBUXXsFwPW2Fd4foL3FrZ94",
  authDomain: "chat-app-users-2c166.firebaseapp.com",
  projectId: "chat-app-users-2c166",
  storageBucket: "chat-app-users-2c166.appspot.com",
  messagingSenderId: "664525356440",
  appId: "1:664525356440:web:4e4c26306e502c687bffed",
  measurementId: "G-KB3VL35D28"
};*/

const firebaseApp = firebase.initializeApp(firebaseConfig);
//const firebaseApp_Users = firebase.initializeApp(firebaseConfig_Users);

const db = firebaseApp.firestore();
//const db_Users = firebaseApp_Users.firestore();

const auth = firebase.auth();

const storage = firebase.storage();

export { db, auth, provider, storage };
