import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Application from "./Components/Application";
import Chat from "./Components/Chat";
import User from "./Components/User";
import Login from "./Components/SignUp";
import Home from "./Components/Home";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { auth, db } from "./Firebase/Firebase";
import firebase from "firebase/app";
import "./App.css";

const favicon = document.getElementById("favicon");
const pageTitle = document.title;
const attentionMessage = "Come back";

function toggle() {
  if (document.title === attentionMessage) {
      document.title = pageTitle;
      favicon.href = "/favicon.ico";
  } else {
      document.title = attentionMessage;
      favicon.href = "/favicon-offline.ico";
  };
};

const onFocus = () => {
    let isPageActive = !document.hidden;

    if (!isPageActive) {
      toggle();
    } else {
      document.title = pageTitle;
    }
    if (!document.hidden) {
      document.title = pageTitle;
      favicon.href = "/favicon.ico";
    }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "#22273b !important",
    height: "100vh",
  },
}));

function App() {
  const classes = useStyles();
  const [user, setUser] = useState(null);

  useEffect(() => {
    window.addEventListener("visibilitychange", onFocus);
  }, []);

  useEffect(() => {
    navigator.serviceWorker.register('service-worker.js');
  }, []);

  useEffect(() => {
    const uid = localStorage.uid;
    window.addEventListener("onunload", function goOfflineBuddy() {
      var url = "http://api.mrayush.me";

      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            // console.log(xhr.status);
            // console.log(xhr.responseText);
        }};

      xhr.send();
    });
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              // console.log("user exits");
              const details = {
                status: "Online",
                timestamp: firebase.firestore.Timestamp.now(),
              };
              db.collection("users")
                .doc(user.uid)
                .update(details)
                .then((res) => {
                  // console.log(res);
                })
                .catch((err) => {
                  // console.log(err);
                });
            } else {
              const details = {
                name: user.displayName,
                displayName: user.displayName.split(" ")[0],
                photoURL: user.photoURL,
                email: user.email,
                status: "Online",
                created_at: firebase.firestore.Timestamp.now(),
                timestamp: firebase.firestore.Timestamp.now(),
                uid: user.uid,
              };
              db.collection("users")
                .doc(user.uid)
                .set(details)
                .then((res) => {
                  // console.log("new user created");
                })
                .catch((err) => {
                  // console.log(err);
                });
            }
          })
          .catch((err) => {
            // console.log(err);
          });

        setUser(user.uid);
      } else {
        setUser(null);
      }
    });
  }, []);

  return (
    <div className="App">
      <Router>
        {!user ? (
          <Login />
        ) : (
          <div className={classes.root}>
            <Application uid={user} />
            <main className={classes.content}>
              <div className={classes.toolbar} style={{ minHeight: "50px" }} />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/channel/:id" component={Chat} />\
                <Route exact path="/user/:id" component={User} />
                <Redirect to="/" />
              </Switch>
            </main>
          </div>
        )}
      </Router>
    </div>
  );
}

export default App;
