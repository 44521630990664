import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import { db } from "../Firebase/Firebase";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { IoMdChatboxes } from "react-icons/io";
import { BiHash } from "react-icons/bi";
import CreateRoom from "./CreateRoom";
import Fade from "@material-ui/core/Fade";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { deepPurple } from "@material-ui/core/colors";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeOffline = withStyles((theme) => ({
  badge: {
    backgroundColor: "#9e9e9e",
    color: "#9e9e9e",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedPersonals: {
    paddingLeft: 0,
  },
  iconDesign: {
    fontSize: "1.5em",
    color: "#cb43fc",
  },
  primary: {
    color: "#cb43fc",
  },
  avatarIcon: {
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: "#3f51b5",
  },
}));

function Rooms() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [openuser, setOpenUser] = React.useState(true);
  const [channelList, setChannelList] = useState([]);
  const [usersList, setusersList] = useState([]);
  const [showCreateRoom, setShowCreateRoom] = useState(false);
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem("userDetails")) ?? {"is_admin": null};
  const handleDrawerToggle = () => {
    // console.log("hsvbhfsdgbdf");
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    db.collection("channels")
      .orderBy("channelName", "asc")
      .onSnapshot((snapshot) => {
        setChannelList(
          snapshot.docs.map((channel) => ({
            channelName: channel.data().channelName,
            id: channel.id,
          }))
        );
      });
  }, []);

  useEffect(() => {
    db.collection("users")
      .orderBy("displayName", "asc")
      .onSnapshot((snapshot) => {
        setusersList(
          snapshot.docs.map((user) => ({
            userName: user.data().displayName,
            userPic: user.data().photoURL,
            id: user.id,
            status: user.data().status,
          }))
        );
      });
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickUser = () => {
    setOpenUser(!openuser);
  };

  const goToChannel = (id) => {
    history.push(`/channel/${id}`);
  };

  const goToUser = (id) => {
    handleDrawerToggle();
    history.push(`/user/${id}`);
  };

  const manageCreateRoomModal = () => {
    setShowCreateRoom(!showCreateRoom);
  };

  const handleAlert = () => {
    setAlert(!alert);
  };

  const addChannel = (cName) => {
    if (cName) {
      cName = cName.toLowerCase().trim();
      if (cName === "") {
        handleAlert();
        return;
      }

      for (var i = 0; i < channelList.length; i++) {
        if (cName === channelList[i].channelName) {
          handleAlert();
          return;
        }
      }

      db.collection("channels")
        .add({ channelName: cName.toLowerCase() ,createdBy: userData.uid ,timestamp: firebase.firestore.Timestamp.now() })
        .then((res) => {
          // console.log("added new channel");
        })
        .then((err) => {
          // console.log(err);
        });
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert}
        onClose={handleAlert}
        TransitionComponent={Fade}
        message="Room Name Already Exits!!"
        key={Fade}
        action={
          <IconButton aria-label="close" color="inherit" onClick={handleAlert}>
            <CloseIcon />
          </IconButton>
        }
      />

      {(userData.is_admin || null) ? (
        <>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemText primary="Create New Friend" />
            <IconButton edge="end" aria-label="add">
              <AddIcon className={classes.primary} />
            </IconButton>
          </ListItem>
          <Divider />
        </>
      ): null}

      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem button onClick={handleClickUser}>
          <ListItemIcon>
            <IoMdChatboxes className={classes.iconDesign} />
          </ListItemIcon>
          <ListItemText primary="FRIENDS" style={{ color: "#8e9297" }} />
          {openuser ? (
            <ExpandLess className={classes.primary} />
          ) : (
            <ExpandMore className={classes.primary} />
          )}
        </ListItem>

        <Collapse in={openuser} timeout="auto">
          <List component="div" style={{ paddingLeft: 15, }}>
            {usersList.map((user) => (
              user.id !== localStorage.uid
              ? user.status === "Online"
                  ? (<ListItem
                      key={user.id}
                      button
                      className={classes.nestedPersonals}
                      onClick={() => goToUser(user.id)}
                    >
                      <div className={classes.avatarIcon}>
                        <StyledBadge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={user.userName}
                            src={user.userPic}
                            className={classes.purple}
                          />
                        </StyledBadge>
                      </div>
                      <ListItemText
                        primary={
                          user.userName === user.userName.substr(0, 12)
                            ? user.userName
                            : `${user.userName.substr(0, 12)}...`
                        }
                        style={{ color: "#dcddde" }}
                      />
                    </ListItem>)
                  : (<ListItem
                    key={user.id}
                    button
                    className={classes.nestedPersonals}
                    onClick={() => goToUser(user.id)}
                  >
                    <div className={classes.avatarIcon}>
                      <StyledBadgeOffline
                        overlap="circle"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          alt={user.userName}
                          src={user.userPic}
                          className={classes.purple}
                        />
                      </StyledBadgeOffline>
                    </div>
                    <ListItemText
                      primary={
                        user.userName === user.userName.substr(0, 12)
                          ? user.userName
                          : `${user.userName.substr(0, 12)}...`
                      }
                      style={{ color: "#dcddde" }}
                    />
                  </ListItem>)
              : null
            ))}
          </List>
        </Collapse>
      </List>

      {showCreateRoom ? (
        <CreateRoom create={addChannel} manage={manageCreateRoomModal} />
      ) : null}
      <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
        <ListItemText primary="Create New Channel" />
        <IconButton edge="end" aria-label="add" onClick={manageCreateRoomModal}>
          <AddIcon className={classes.primary} />
        </IconButton>
      </ListItem>
      <Divider />

      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <IoMdChatboxes className={classes.iconDesign} />
          </ListItemIcon>
          <ListItemText primary="CHANNELS" style={{ color: "#8e9297" }} />
          {open ? (
            <ExpandLess className={classes.primary} />
          ) : (
            <ExpandMore className={classes.primary} />
          )}
        </ListItem>

        <Collapse in={open} timeout="auto">
          <List component="div" disablePadding>
            {channelList.map((channel) => (
              <ListItem
                key={channel.id}
                button
                className={classes.nested}
                onClick={() => goToChannel(channel.id)}
              >
                <ListItemIcon style={{ minWidth: "30px" }}>
                  <BiHash
                    className={classes.iconDesign}
                    style={{ color: "#b9bbbe" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    channel.channelName === channel.channelName.substr(0, 12)
                      ? channel.channelName
                      : `${channel.channelName.substr(0, 12)}...`
                  }
                  style={{ color: "#dcddde" }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default Rooms;
